import "./Header.scss";
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { restoreHeaderBottom } from "Utils/restoreHeaderBottom";
import { getWindowWidth } from "Utils/getWindowWidth";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { changeAuthModalVisibility, changeGender, saveMainMenu } from "Redux/slices/appSlice";
import { UserControl } from "./components/UserControl";
import { fetchApi } from "Utils/fetchApi";
import { genderList } from "Static/texts";
import { LocationSelect } from "Components/LocationSelect/LocationSelect";
import { MobileMenu } from "Components/MobileMenu/MobileMenu";
import { MapPointIcon } from "Svg/MapPointIcon";
import { PhoneIcon } from "Svg/PhoneIcon";
import { MenuIcon } from "Svg/MenuIcon";
import { StoreLogoIcon } from "Svg/StoreLogoIcon";
import { PseudoSearchInput } from "Components/PseudoSearchInput/PseudoSearchInput";
import { SearchIcon } from "Svg/SearchIcon";
import { NotificationCounter } from "Components/NotificationCounter/NotificationCounter";
import { ShopingBagIcon } from "Svg/ShopingBagIcon";
import { HeartIcon } from "Svg/HeartIcon";
import { SkeletonsList } from "Components/SkeletonsList/SkeletonsList";
export const Header = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { city, mainMenu, genderData, deviceInfo, breakpointMatches, isHeaderShown } = useAppSelector((state) => state.app);
    const { user } = useAppSelector((state) => state.userInfo);
    const abortController_navMenu = useRef(null);
    useEffect(() => {
        document.addEventListener("scroll", scrollHandler);
        return () => {
            document.removeEventListener("scroll", scrollHandler);
        };
    }, []);
    let lastScrollTop = 0;
    const scrollHandler = () => {
        const windowSize = getWindowWidth();
        const currentYScroll = window.scrollY;
        const headerTop = document.querySelector(".header__top");
        const isContainsTop = headerTop ? headerTop.classList.contains("header__top_shadow") : null;
        if (currentYScroll >= 35 && windowSize <= 960 && !isContainsTop && headerTop) {
            headerTop.classList.add("header__top_shadow");
        }
        else if (currentYScroll <= 35 && windowSize <= 960 && isContainsTop && headerTop) {
            headerTop.classList.remove("header__top_shadow");
        }
        else if (windowSize >= 960 && isContainsTop && headerTop) {
            headerTop.classList.remove("header__top_shadow");
        }
        const headerBottom = document.querySelector(".header__bottom");
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if ((currentYScroll <= 118 && scrollTop < lastScrollTop) || scrollTop > lastScrollTop) {
            restoreHeaderBottom();
        }
        else if (currentYScroll > 118 && scrollTop < lastScrollTop) {
            if (windowSize > 960 && headerTop) {
                headerTop.style.marginBottom = "89px";
            }
            headerBottom === null || headerBottom === void 0 ? void 0 : headerBottom.classList.add("header__bottom_fixed");
        }
        lastScrollTop = scrollTop;
        setTimeout(() => {
            window.scrollY === 0 && restoreHeaderBottom();
        }, 100);
    };
    const [isNavMenuLoading, setIsNavMenuLoading] = useState(false);
    useEffect(() => {
        setIsNavMenuLoading(true);
        const newAbortController = new AbortController();
        abortController_navMenu.current && abortController_navMenu.current.abort();
        abortController_navMenu.current = newAbortController;
        fetchApi({
            method: "GET",
            url: `/main-menu?sex=${genderData.genderNumber}`,
            signal: newAbortController.signal,
        })
            .then(({ data }) => {
            dispatch(saveMainMenu(data));
            abortController_navMenu.current = null;
            setIsNavMenuLoading(false);
        })
            .catch((e) => console.error(e));
    }, [genderData]);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const mobileMenuHandler = () => {
        setShowMobileMenu((prev) => !prev);
    };
    const closeMenu = () => {
        setShowMobileMenu(false);
    };
    const [showCitySelection, setShowCitySelection] = useState(false);
    const openCitySelection = () => {
        if (showMobileMenu) {
            setShowMobileMenu(false);
        }
        setShowCitySelection(true);
    };
    const closeCitySelection = () => {
        setShowCitySelection(false);
    };
    const openAuthModal = () => {
        dispatch(changeAuthModalVisibility(true));
    };
    return isHeaderShown ? (React.createElement("header", { className: 'header__wrapper', "data-browser": deviceInfo.browserName },
        React.createElement(LocationSelect, { onCancel: closeCitySelection, userCity: city, visible: showCitySelection }),
        React.createElement(MobileMenu, { menuList: mainMenu, closeMenu: closeMenu, showMobileMenu: showMobileMenu, openCitySelection: openCitySelection }),
        React.createElement("div", { className: 'header__sub-wrapper' },
            React.createElement("div", { className: 'header__attic' },
                React.createElement("div", { className: 'header__attic__item' },
                    React.createElement("div", { className: 'header__attic__item__citySelection', onClick: openCitySelection },
                        React.createElement(MapPointIcon, null),
                        React.createElement("span", { className: 'typography_tec_13_semiBoldThin' }, city === null || city === void 0 ? void 0 : city.city_name)),
                    React.createElement(NavLink, { className: 'typography_tec_13_semiBoldThin header__attic__item__pickupLink', to: `/city/${city === null || city === void 0 ? void 0 : city.city_name}/` }, "\u041F\u0443\u043D\u043A\u0442\u044B \u0432\u044B\u0434\u0430\u0447\u0438 \u0437\u0430\u043A\u0430\u0437\u043E\u0432")),
                React.createElement("div", { className: 'header__attic__item' },
                    React.createElement("div", { className: 'header__attic__item__contacts' },
                        React.createElement(PhoneIcon, null),
                        React.createElement("a", { className: 'typography_tec_13_semiBoldThin mgo-number-32781', href: 'tel:+74994553983' }, "+7 (499) 455-39-83")),
                    React.createElement("time", { className: 'typography_tec_13_semiBoldThin header__attic__item__workTime', dateTime: 'Mo-Su, 09:00-22:00' }, "\u0415\u0436\u0435\u0434\u043D\u0435\u0432\u043D\u043E \u0441 09:00 \u0434\u043E 22:00"))),
            React.createElement("div", { className: 'header__top' },
                React.createElement("div", { className: 'header__top__genderSelection typography_tec_13_semiBold' }, genderList.map((gender) => (React.createElement("p", { className: "header__top__genderSelection__control" +
                        (genderData.genderNumber === gender.genderNumber
                            ? " header__top__genderSelection__control_active"
                            : ""), key: gender.genderNumber, onClick: () => dispatch(changeGender(gender)) }, gender.gender)))),
                React.createElement("div", { className: 'header__top__icons header__top__icons_left mobileContent' },
                    React.createElement(MenuIcon, { onClick: mobileMenuHandler })),
                React.createElement(NavLink, { className: 'header__top__logo', to: '/', "aria-label": 'Link to home page' },
                    React.createElement(StoreLogoIcon, null)),
                React.createElement("div", { className: 'header__top__icons header__top__icons_right' },
                    React.createElement(PseudoSearchInput, { className: 'header__top__icons_right__search desctopContent' }),
                    React.createElement(UserControl, { className: 'desctopContent', openAuthModal: openAuthModal, user: user }),
                    breakpointMatches[960] ? (React.createElement(SearchIcon, { className: 'AnyQuery__control' })) : (React.createElement(React.Fragment, null,
                        React.createElement(NavLink, { className: 'header__top__icons__notificationsWrapper', to: '/favourites/', "aria-label": 'Favourites page link' },
                            React.createElement(NotificationCounter, { className: 'header__top__icons__counter', type: 'favourites' }),
                            React.createElement(HeartIcon, { className: 'header__top__icons__notificationsWrapper__favouritesIcon' })),
                        React.createElement(NavLink, { className: 'header__top__icons__notificationsWrapper', to: '/cart/', "aria-label": 'Cart page link' },
                            React.createElement(NotificationCounter, { className: 'header__top__icons__counter', type: 'cart' }),
                            React.createElement(ShopingBagIcon, { className: 'header__top__icons__notificationsWrapper__cartIcon' })))))),
            React.createElement("div", { className: 'header__bottom' },
                React.createElement("nav", { className: 'header__bottom__menu' }, isNavMenuLoading ? (React.createElement(SkeletonsList, { quantity: 9 },
                    React.createElement("div", { className: 'header__bottom__menu__skeletonItem skeletonLoading' }))) : (mainMenu.map((item) => (React.createElement(NavLink, { className: "header__bottom__menu__item typography_tec_15_menu" +
                        (location.pathname === item.url ? " header__bottom__menu__item_selected" : ""), "data-is-highlight": item.highlight, to: item.url, key: item.name, id: item.category_id }, item.name))))))))) : null;
};
