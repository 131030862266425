import "./ClientWidget.scss";
import React, { useState } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { WA_number } from "Static/variables";
import { MessageSvg } from "./svg/MessageSvg";
import { ChatSvg } from "./svg/ChatSvg";
import { CustomDrawer } from "Components/CustomDrawer/CustomDrawer";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { IconPopover } from "./components/IconPopover";
export const ClientWidget = () => {
    const { deviceInfo, clientWidgetSubClass } = useAppSelector((state) => state.app);
    const [isWidgetOpened, setIsWidgetOpened] = useState(false);
    const openWidget = () => {
        deviceInfo.deviceType === "desktop" && setIsWidgetOpened(true);
    };
    const closeWidget = () => {
        setIsWidgetOpened(false);
    };
    const popoverClickHandler = () => {
        deviceInfo.deviceType === "mobile" ? openDrawer() : openWidget();
    };
    const openMessanger = (type) => {
        const msg = "Пожалуйста, отправьте это сообщение и дождитесь ответа";
        window.open(type === "telegram"
            ? "https://t.me/lsnet_content"
            : type === "viber"
                ? `viber://pa?chatURI=lsnetru&text=${msg}`
                : `https://wa.me/${WA_number}?text=${msg}`);
        deviceInfo.deviceType === "mobile" && closeDrawer();
    };
    const [isDrawerShown, setIsDrawerShown] = useState(false);
    const openDrawer = () => {
        setIsDrawerShown(true);
    };
    const closeDrawer = () => {
        setIsDrawerShown(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "clientWidget" +
                (isWidgetOpened ? " clientWidget_opened" : "") +
                (clientWidgetSubClass ? ` ${clientWidgetSubClass}` : ""), onClick: popoverClickHandler, onMouseEnter: openWidget, onMouseLeave: closeWidget }, isWidgetOpened ? (React.createElement(React.Fragment, null,
            React.createElement(IconPopover, { messangerName: '\u043C\u0435\u0441\u0441\u0435\u043D\u0434\u0436\u0435\u0440' },
                React.createElement(ChatSvg, { className: 'clientWidget__icon', onClick: () => openMessanger("whatsapp") })))) : (React.createElement(MessageSvg, { className: 'clientWidget__icon' }))),
        React.createElement(CustomDrawer, { rootClassName: 'clientWidget__drawer customServiceDrawer_bottom', placement: 'bottom', open: isDrawerShown, destroyOnClose: true, onClose: closeDrawer },
            React.createElement("div", { className: 'customServiceDrawer_bottom__title' },
                React.createElement("span", { className: 'typography_tec_15_menu' }, "\u0417\u0430\u0434\u0430\u0442\u044C \u0432\u043E\u043F\u0440\u043E\u0441"),
                React.createElement(CloseIcon, { className: 'customServiceDrawer_bottom__title__closeIcon', size: 'small', withBackground: true, onClick: closeDrawer })),
            React.createElement("div", { className: 'clientWidget__drawer__content customServiceDrawer_bottom__content', style: {
                    maxHeight: deviceInfo.deviceType === "mobile"
                        ? document.documentElement.clientHeight - 112 + "px"
                        : "calc(100vh - 112px)",
                } },
                React.createElement("button", { className: 'clientWidget__drawer__content__control customBtn customBtn_withPrefix customBtn_outline', onClick: () => openMessanger("whatsapp") },
                    React.createElement(ChatSvg, { className: 'clientWidget__drawer__content__control__icon' }),
                    React.createElement("span", null, "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 \u043C\u0435\u0441\u0441\u0435\u043D\u0434\u0436\u0435\u0440")),
                React.createElement("button", { className: 'clientWidget__drawer__content__control customBtn customBtn_containedGrey', onClick: closeDrawer }, "\u041E\u0442\u043C\u0435\u043D\u0430")))));
};
