import { apiUrl } from "Static/variables";
import { changeSnackbarData } from "Redux/slices/appSlice";
import { deleteCookie } from "Utils/deleteCookie";
import { canUseWebP } from "Utils/canUseWebP";
const isSupportWebp = canUseWebP();
export const fetchApi = async ({ body, credentials, headers, hideErrorSnackbar = false, method, url, ...otherConfigs }) => {
    const store = await import("Redux/store");
    const token = localStorage.getItem("token") || "";
    if (token === "1928374650")
        return Promise.reject();
    const isFormData = body && body instanceof FormData;
    let config = {
        headers: {
            Authorization: token,
            "X-Platform": "web",
            ...(!isFormData && { "Content-Type": "application/json" }),
            ...(isSupportWebp && { "X-Support-Webp": "1" }),
            ...headers,
        },
        credentials: credentials || "include",
        ...otherConfigs,
    };
    if (method !== "GET" && method !== "DELETE" && body)
        config.body = isFormData ? body : JSON.stringify(body);
    return fetch(apiUrl + url, {
        method,
        ...config,
    })
        .then(async (res) => {
        var _a;
        if (!res.ok) {
            const data = await res.json();
            const errMsg = res.statusText ||
                (data === null || data === void 0 ? void 0 : data.message) ||
                (data.length > 0 && ((_a = data[0]) === null || _a === void 0 ? void 0 : _a.message)) ||
                `Request failed with status code ${res.status}`;
            throw {
                code: res.status,
                message: errMsg,
                name: data.name || "FetchError",
                url: res.url,
            };
        }
        return {
            data: await res.json(),
            status: res.status,
            statusText: res.statusText,
            headers: res.headers,
        };
    })
        .catch(async (err) => {
        if (err.name === "AbortError") {
            throw { name: "AbortError", message: "canceled" };
        }
        else if (err.code) {
            if ((err === null || err === void 0 ? void 0 : err.code) === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("favourites");
                localStorage.removeItem("cart");
                deleteCookie("user_id");
                deleteCookie("hashcode");
            }
            !hideErrorSnackbar && store.default.dispatch(changeSnackbarData({ message: err.message, status: "error" }));
            throw err;
        }
        else {
            !hideErrorSnackbar && store.default.dispatch(changeSnackbarData({ message: "Network Error", status: "error" }));
            throw { name: "FetchError", code: "ERR_NETWORK", message: "Network Error" };
        }
    });
};
