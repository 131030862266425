import { createSlice } from "@reduxjs/toolkit";
const setLocalItem = (name, item) => {
    return localStorage.setItem(name, JSON.stringify(item));
};
const resetLocalStorage = () => {
    localStorage.removeItem('returnOrder');
    localStorage.removeItem('step');
    localStorage.removeItem('deliveryMethod');
    localStorage.removeItem('paymentMethod');
    localStorage.removeItem('userData');
    localStorage.removeItem('returnsPageType');
};
const initialOrder = JSON.parse(localStorage.getItem('returnOrder') || 'null') || {
    id: null,
    date: null,
    acceptDate: null,
    daysAfterAccept: null,
    availableReturnWithoutDefect: null,
    availableReturnWithDefect: null,
    client: null,
    acceptedProducts: [],
    availableDeliveryMethods: {
        pvz: [],
        courier: []
    },
};
const initialStep = JSON.parse(localStorage.getItem('step') || 'null') || {
    number: 0,
    subStep: 0,
    isValid: true
};
const initialDeliveryMethod = JSON.parse(localStorage.getItem('deliveryMethod') || 'null') || {
    method: 'pvz',
    id: null,
    uuid: null,
    name: null,
    address: null,
    courierVisitDate: null,
    courierVisitTimeFrom: null,
    courierVisitTimeTo: null,
    price: null
};
const initialPaymentMethod = JSON.parse(localStorage.getItem('paymentMethod') || 'null') || null;
const initialUserData = JSON.parse(localStorage.getItem('userData') || 'null') || null;
const initialReturnsPageType = JSON.parse(localStorage.getItem('returnsPageType') || 'null') || 'returns';
const initialState = {
    returnOrder: initialOrder,
    step: initialStep,
    deliveryMethod: initialDeliveryMethod,
    paymentMethod: initialPaymentMethod,
    userData: initialUserData,
    returnsPageType: initialReturnsPageType
};
export const returnSlice = createSlice({
    name: 'return',
    initialState,
    reducers: {
        saveReturnOrder(state, action) {
            state.returnOrder = action.payload;
            setLocalItem('returnOrder', state.returnOrder);
        },
        changeReturnProduct(state, action) {
            state.returnOrder.acceptedProducts[action.payload.index] = action.payload.item;
            setLocalItem('returnOrder', state.returnOrder);
        },
        setReturnStep(state, action) {
            state.step = {
                number: action.payload.number,
                subStep: action.payload.subStep,
                isValid: true
            };
            setLocalItem('step', state.step);
        },
        changeReturnStepValid(state, action) {
            state.step.isValid = action.payload;
            setLocalItem('step', state.step);
        },
        setDeliveryMethod(state, action) {
            state.deliveryMethod = action.payload;
            setLocalItem('deliveryMethod', state.deliveryMethod);
        },
        setPaymentMethod(state, action) {
            state.paymentMethod = action.payload;
            setLocalItem('paymentMethod', state.paymentMethod);
        },
        setUserData(state, action) {
            state.userData = action.payload;
            setLocalItem('userData', state.userData);
        },
        changeUserData(state, action) {
            state.userData = { ...state.userData, ...action.payload };
            setLocalItem('userData', state.userData);
        },
        setReturnsPageType(state, action) {
            state.returnsPageType = action.payload;
            setLocalItem('returnsPageType', state.returnsPageType);
        },
        resetReturn(state) {
            state.returnOrder = {
                id: null,
                date: null,
                acceptDate: null,
                daysAfterAccept: null,
                availableReturnWithoutDefect: null,
                availableReturnWithDefect: null,
                client: null,
                acceptedProducts: [],
                availableDeliveryMethods: {
                    pvz: [],
                    courier: []
                },
            };
            state.step = {
                number: 0,
                subStep: 0,
                isValid: true
            };
            state.deliveryMethod = {
                method: 'pvz',
                id: null,
                uuid: null,
                name: null,
                address: null,
                courierVisitDate: null,
                courierVisitTimeFrom: null,
                courierVisitTimeTo: null,
                price: 0
            };
            state.paymentMethod = null;
            resetLocalStorage();
        },
    }
});
export const { saveReturnOrder, changeReturnProduct, resetReturn, setReturnStep, changeReturnStepValid, setDeliveryMethod, setPaymentMethod, setUserData, changeUserData, setReturnsPageType } = returnSlice.actions;
export default returnSlice.reducer;
